import {
  ExceptionItem,
  MultiExchangeItem,
  TshirtItem,
} from "../../Components/Profile/Exchange";
import {
  KitModel,
  KitProductModel,
  ProductModel,
} from "../Models/product.model";
import { ReturnModel } from "../Models/return.model";
import { RiderModel, RiderProductModel } from "../Models/rider.model";
import { WarrantyModel } from "../Models/warranty.model";
import request, { Method } from "../request";

export interface OrderDataInterface {
  product_id: number;
  product_variation_id: number | null;
  quantity: number;
  checked: boolean;
}

class StockService {
  async search(searchId: string) {
    return request<RiderModel[]>(`/profile/search/${searchId}`, Method.GET);
  }

  async get(riderId: string) {
    return request<RiderModel>(`/profile/${riderId}`, Method.GET);
  }

  async getMissingKitProducts(riderId: string) {
    return request<KitModel>(
      `/profile/${riderId}/missing-kit-products`,
      Method.GET
    );
  }

  async update(
    riderId: string,
    idVerified: boolean,
    size: string | null,
    vehicle: number | null,
    image: File | null
  ) {
    let formData = new FormData();
    formData.append("id_verified", idVerified ? "1" : "0");
    if (size) {
      formData.append("size", size);
    }

    if (vehicle) {
      formData.append("vehicle_type", vehicle.toString());
    }

    if (image) {
      formData.append(`image`, image);
    }

    return request<RiderModel>(`/profile/${riderId}`, Method.POST, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async assignKit(
    riderId: string,
    kitId: number,
    products: KitProductModel[],
    images: File[]
  ) {
    let formData = new FormData();

    images.forEach((image) => {
      formData.append(`images[]`, image);
    });

    products.forEach((product) => {
      formData.append(
        `products[${product.id}][kit_product_id]`,
        product.id.toString()
      );
      formData.append(
        `products[${product.id}][replacement]`,
        product.replacement ? "1" : "0"
      );
      if (product.selectedVariationId) {
        formData.append(
          `products[${product.id}][variation_id]`,
          product.selectedVariationId.toString()
        );
      }
    });

    formData.append("kit_id", kitId.toString());

    return request(`/profile/${riderId}/assign-kit`, Method.POST, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async assignGearItem(
    riderId: string,
    productId: number,
    variationId: number | null,
    date: string,
    quantity: number
  ) {
    return request(`/profile/${riderId}/assign-gear-item`, Method.POST, {
      data: {
        product_id: productId,
        product_variation_id: variationId,
        date: date,
        quantity: quantity,
      },
    });
  }

  async searchProducts(riderId: string, q: string, page: number = 0) {
    const params = new URLSearchParams({
      q: q,
      page: page.toString(),
    });

    return request<ProductModel[]>(
      `/profile/search-products/${riderId}?${params}`,
      Method.GET
    );
  }

  async listTshirts(riderId: string) {
    return request<ProductModel[]>(
      `/profile/get-tshirts/${riderId}`,
      Method.GET
    );
  }

  async listGear(riderId: string) {
    return request<RiderProductModel[]>(
      `/profile/list-gear/${riderId}`,
      Method.GET
    );
  }

  async getReturn(riderId: string) {
    return request<ReturnModel>(`/profile/get-return/${riderId}`, Method.GET);
  }

  async getAssignTshirtsCount(riderId: string) {
    return request<{ maxTshirts: number; returnModifier: number }>(
      `/profile/${riderId}/assign-tshirts-count`,
      Method.GET
    );
  }

  async createExchange(riderId: string, gearId: number) {
    return request<WarrantyModel>(
      `/profile/${riderId}/create-exchange`,
      Method.POST,
      {
        data: {
          profile_product_id: gearId,
        },
      }
    );
  }

  async multiExchange(
    riderId: string,
    gears: MultiExchangeItem[],
    returnedTshirts: TshirtItem[],
    dispatchedTshirts: TshirtItem[],
    missingProducts: KitProductModel[],
    exceptions: ExceptionItem[],
    image: File
  ) {
    return request<WarrantyModel>(
      `/profile/${riderId}/multiple-exchange`,
      Method.POST,
      {
        data: {
          profile_product_ids: gears.map((item) => ({
            gear_id: item.gearId,
            variation_id: item.variationId,
            new_product_id: item.groupSelectedProductId,
            new_product_variation_id: item.groupSelectedProductVariationId,
          })),
          returned_tshirts: returnedTshirts.map((item) => ({
            product_id: item.productId,
            variation_id: item.variationId,
            quantity: item.quantity,
          })),
          dispatched_tshirts: dispatchedTshirts.map((item) => ({
            product_id: item.productId,
            variation_id: item.variationId,
            quantity: item.quantity,
          })),
          missing_items: missingProducts.map((item) => ({
            kit_product_id: item.id,
            variation_id: item.selectedVariationId,
            replacement: item.replacement ? "1" : "0",
          })),
          exceptions: exceptions.map((item) => ({
            gear_id: item.gearId,
            variation_id: item.variationId,
            condition_id: item.condition,
            note: item.note,
            image: item.image,
          })),
          image: image,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  async getNewgearItems(riderId: number) {
    return request<RiderProductModel[]>(`/profile/${riderId}/new-kit-products`, Method.GET);
  }

  async approveOrder(riderId: number) {
    return request(`/profile/${riderId}/approve-order`, Method.POST);
  }

  async declineOrder(riderId: number) {
    return request(`/profile/${riderId}/decline-order`, Method.POST);
  }



}

export const riderService = new StockService();
