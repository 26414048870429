import { useState, useEffect } from "react";
import { riderService } from "../../../Api/Services/RiderService";
import { RiderProductModel } from "../../../Api/Models/rider.model";
import { useTranslation } from "react-i18next";
import React from "react";
import { useInfoModal  } from "../../../Context/InfoModalProvider";


const NewGearScreen = ({ riderId }: { riderId: number }) => {
    const [gearItems, setGearItems] = useState<RiderProductModel[]>([]);
    const { t } = useTranslation();
    const { showInfo } = useInfoModal();

    useEffect(() => {
        const fetchGearItems = async () => {
            try {
                const response = await riderService.getNewgearItems(riderId);
                setGearItems(response);
                console.log("Fetched gear items:", response);
            } catch (error) {
                console.error("Failed to fetch gear items:", error);
            }
        };

        fetchGearItems();
    }, [riderId]);

    const handleApproveOrder = async () => {
        try {
            await riderService.approveOrder(riderId);
            window.location.reload();
        } catch (error) {
            console.error("Failed to approve order:", error);
            showInfo("Failed to approve order.");
        }
    };

    const handleDeclineOrder = async () => {
        try {
            await riderService.declineOrder(riderId);
            window.location.reload();
        } catch (error) {
            console.error("Failed to decline order:", error);
            showInfo("Failed to decline order.");
        }
    };

    return (
        <div>
            <p className="text-white text-center text-large mb-3">
                {t("Confirm items")}
            </p>

            <div style={{margin: "0 10%"}} className="flex flex-col">
                {gearItems.map((item) => (
                    <div key={item.id} className="flex mb-3">
                        <div className="kit-product-header w-full">
                            <img
                                src={item.productImage ?? ""}
                                width="40px"
                                alt={item.productName}
                            />
                            <div className="product-name-wrapper">
                                {item.productName}
                                {item.variationName && ` - ${item.variationName}`}
                            </div>
                            <div className="product-quantity">
                                {item.quantity} {t("pcs")}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex justify-center mt-4">
                <button
                    className="button button--green mr-3"
                    onClick={handleApproveOrder}
                >
                    {t("Approve")}
                </button>
                <button
                    className="button button-2--declined"
                    onClick={handleDeclineOrder}
                >
                    {t("Decline")}
                </button>
            </div>
        </div>
)
    ;
};

export default NewGearScreen;
