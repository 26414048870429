import { ProductModel } from "./product.model";
import { ReturnStatus } from "./return.model";

export enum RiderStatus {
  PENDING = 1,
  ACITVE = 2,
  INACTIVE = 3,
  CLEARANCE = 4,
}

export enum ProfileType {
  RIDER = 1,
  TPATROLLER = 2,
  FULL_TIME = 3,
}

export interface RiderModel {
  tpl_name: string;
  email: string;
  riderId: string;
  status: RiderStatus;
  vehicle: number;
  name: string;
  idVerified: boolean;
  image: string;
  joinDate: string;
  profileType: ProfileType;
  onboardingSkipped: boolean;
  hasOrder: string;
}

export interface RiderProductModel {
  id: number;
  productId: number;
  productName: string;
  productVariationId: number | null;
  variationName: string | null;
  productImage: string;
  assignedAt: string;
  exchangeAvailableAt: string | null;
  exchangeDays: number | null;
  exchangedDisabled: boolean | undefined;
  returnStatus: ReturnStatus | null;
  returnDate: string | null;
  discontinued: boolean;
  exchangeList: "none" | "size_change" | "eligible" | "accident" | "exception";
  exchangeSelected?: boolean;
  availableVariations: [
    {
      id: number;
      name: string;
    }
  ];
  isTshirt: boolean;
  replacementProduct?: ProductModel | null;
  groupProducts?: ProductModel[] | null;
  quantity: number|null;
}
